#prices {
    @include title;

    aside {
        h5 {
            background-color: #fff;
            color: #222222;
            padding: 10px;
            text-align: center;
            font-size: 1.25rem;

            span {
                color: $c1;
            }
        }
    }

    .prices-content {
        display: flex;
        justify-content: center;
        gap: 30px;
        flex-wrap: wrap;
        margin-top: 50px;

        .prices-card {
            background-color: $dc1;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-block: 40px;
            text-align: center;
            width: 275px;
            height: 100%;
            gap: 20px;

            .price-tags {
                list-style: none;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                padding-inline: 5px;
                gap: 5px;

                li {
                    border: 2px solid;
                    padding: 5px;
                    border-radius: 5px;
                    font-size: .75rem;
                    color: $c1;
                    font-weight: 900;
                }
            }

            .price-desc {
                font-size: .75rem;
                font-weight: 700;
                padding-inline: 10px;
            }

            .price-block {

                ul {
                    list-style: none;

                    li {
                        display: flex;
                        align-items: center;
                        padding: 10px;

                        &:nth-child(2n+1) {
                            transition: background-color 0.3s ease-in, color 0.3s ease-in;
                            background-color: $dc3;
                        }

                        span {
                            font-size: 2rem;
                            padding-right: 10px;
                        }

                        p {
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            font-size: .90rem;
                        }
                    }
                }

                select {
                    width: 100%;
                    border: none;
                    background-color: $c1;
                    padding: 20px 10px;
                    font-weight: 600;
                    color: $dc1;
                    cursor: pointer;
                    transition: background-color 0.3s ease-in, color 0.3s ease-in;

                }
            }

            &:hover .price-tags li {
                color: $dc3;
                background-color: $c1;
                border-color: $c1;
            }

            &:hover .price-block li:nth-child(2n+1) {
                background-color: $c1;
                color: $dc3;
            }

            &:hover ul {
                background-color: $dc1;
            }

            &:hover .price-block select {
                background-color: $dc3;
                color: $c1;
            }
        }

        .small-prices-cards {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            height: 100%;
            gap: 20px;

            @media only screen and (max-width: 569px) {
                flex-wrap: wrap;
                justify-content: center;
            }

            .small-card {
                width: 275px;
                background-color: $dc3;
                padding-block: 40px;
                display: flex;
                justify-content: center;
                .small-card-desc {
                    font-size: .75rem;
                    margin-inline: 5px;
                }
            }

            @media only screen and (max-width: 1279px) {
                flex-direction: row;
            }
        }

        .price-card-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            gap: 20px;

            h6 {
                color: $c1;
                font-weight: 600;
                font-size: 1rem;
                width: 200px;
                line-height: 1.5em;
                line-break: 1.5em;
            }

            .line-barre {
                width: 50px;
                border: none;
                height: 5px;
                background-color: $c1;
            }

            b {
                display: flex;
            }

            h5 {
                font-size: 3rem;
                font-weight: 700;

                span {
                    font-size: 1.5rem;
                    vertical-align: top;
                    font-weight: 300;
                }
            }
        }

        button {
            background-color: $c1;
            font-weight: 700;
            padding: 10px 40px;
            color: $dc3;
            border: 2px solid $c1;
            cursor: pointer;
            transition: background-color 0.3s ease-in, color 0.3s ease-in;

            &:hover {
                background-color: $dc1;
                color: #fff;
            }
        }
    }
}