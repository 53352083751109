.modal-videos_overlay {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    padding: 100px;

    @media only screen and (max-width: 768px) {
        padding-inline: 50px;
    }

    @media only screen and (max-width: 545px) {
        padding-inline: 0;
    }

    .modal-videos_content,
    .modal-videos_maintenance {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
        outline: none;
    }

    .modal-videos_content {
        width: 100%;
        height: 100%;

        .modal-video {
            border: none;
            width: 100%;
            height: 700px;


            @media only screen and (max-width: 768px) {
                height: 400px;
            }

            @media only screen and (max-width: 545px) {
                height: 200px;
            }
        }

    }

    .modal-videos_maintenance {
        width: max-content;
        height: 10px;

        p {
            font-size: 1rem;
            font-weight: 700;
        }
    }
}

.ReactModal__Body--open {
    overflow: hidden;
}