#projects {
  @include title;
  padding-bottom: 100px;

  a {
    color: $c1;
    text-decoration: none;
  }

  .project-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-inline: 20px;

    section {
      background-color: $dc3;
      height: 350px;
      width: 300px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      position: relative;

      .project-img {
        cursor: pointer;

        .little-effect {
          position: absolute;
          cursor: default;
          bottom: 149px;
          left: 0;
          width: 75px;
          height: 75px;
          background-color: $dc3;
          clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
        }

        &:hover::before {
          content: "+";
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          color: #000;
          font-size: 5rem;
          width: 100%;
          height: 200px;
          background-color: $c1;
          opacity: 0.75;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
      }

      .project-text-block {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          li {
            list-style: none;
            padding: 5px;
            border-radius: 5px;
            border: 2px solid $c1;
            transition: border-color 0.3s ease-in;

            &:hover {
              border-color: white;
            }

            a {
              transition: color 0.3s ease-in;
              font-size: 12px;
              color: $c1;
              text-decoration: none;

              &:hover {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}