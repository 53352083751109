#contact {
    @include title;

    h5 {
        background-color: #fff;
        color: #222222;
        padding: 10px;
        text-align: center;
        font-size: 1.25rem;
        margin-bottom: 30px;

        span {
            color: $c1;
        }
    }

    .contact-content {
        display: flex;
        justify-content: space-between;
        margin-inline: 20px;

        @media only screen and (max-width: 1190px) {
            font-size: 1.25rem;
            flex-wrap: wrap;
        }

        .contact-info {
            width: 25%;

            @media only screen and (max-width: 1190px) {
                display: flex;
                width: 100%;
                justify-content: space-around;
                margin-bottom: 20px;
            }

            @media only screen and (max-width: 550px) {
                flex-direction: column;
                text-align: center;

                article {
                    flex-direction: column;
                    align-items: center;
                }
            }

            article {
                padding: 10px;
                gap: 10px;
                display: flex;

                @media only screen and (max-width: 1190px) {
                    &:last-child hr {
                        visibility: hidden;
                    }
                }

                @media only screen and (max-width: 550px) {
                    &:last-child hr {
                        visibility: visible;
                    }
                }

                svg {
                    color: $c1;
                }

                .contact-info-text {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 10px;
                    padding-top: 2px;

                    h6 {
                        font-size: 1rem;
                        margin-bottom: 10px;
                    }

                    b,
                    p,
                    a {
                        font-size: .75rem;
                    }

                    .contact-portfolio-link {
                        display: flex;
                        flex-direction: column;

                        a {
                            color: #fff;
                            text-decoration: none;
                            transition: color .3s ease-in;

                            &:hover {
                                color: $c1;
                                text-decoration: underline;
                            }
                        }
                    }

                    .line-barre {
                        border: 2px solid $c1;
                    }
                }

            }
        }

        .contact-map-form {
            display: flex;
            justify-content: space-between;

            @media only screen and (max-width: 950px) {
                flex-wrap: wrap;
            }

            .contact-map {
                width: calc(50% - 15px);
                border: 5px solid $c1;
                margin-inline: 30px;

                @media only screen and (max-width: 1190px) {
                    margin-inline: 0 30px;
                }

                @media only screen and (max-width: 950px) {
                    width: 100%;
                    margin: 0;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    filter: grayscale(100%);
                    position: relative;

                }
            }

            // .bloc-map {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     height: 450px;
            //     width: 25%;
            //     // @media only screen and (max-width: 875px) {
            //     //     margin: 75px 0 0 0;
            //     //     height: 750px;
            //     // }
            // }

            form {
                display: flex;
                flex-direction: column;
                width: 50%;
                gap: 20px;

                @media only screen and (max-width: 950px) {
                    margin-top: 20px;
                    width: 100%;
                }

                .form-block {
                    display: flex;

                    svg {
                        background-color: $c1;
                        color: $dc2;
                        padding: 5px;
                        width: 32px;
                        height: 100%;
                    }

                    input {
                        background-color: inherit;
                        border-width: 2px 0;
                        border-color: $c1;
                        color: #fff;
                        border-style: solid;
                        width: 100%;
                        padding: 10px;
                        caret-color: inherit;

                        &:focus {
                            outline: none;
                        }

                        &[type="tel"] {
                            border-width: 2px 2px 2px 0;
                        }
                    }

                    .form-required {
                        background-color: #cbb488;
                        color: $dc2;
                        padding: 5px;
                        width: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                }

                textarea {
                    background-color: inherit;
                    border-width: 2px 0 2px 2px;
                    border-color: $c1;
                    border-style: solid;
                    width: 100%;
                    padding: 10px;
                    resize: vertical;
                    color: #fff;
                    min-height: 185px;
                    max-height: 500px;

                    &:focus {
                        outline: none;
                    }
                }

                .contact-form-text {
                    display: flex;
                    align-items: flex-start;
                    position: relative;
                    padding-left: 35px;
                    font-size: .75rem;

                    input[type="checkbox"] {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 25px;
                        width: 25px;
                        opacity: 0;
                    }

                    span {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 25px;
                        width: 25px;
                        border: 2px solid $c1;

                        &.checked {
                            padding: 3px;

                            &::after {
                                content: "";
                                width: 100%;
                                height: 100%;
                                background-color: #fff;
                                display: flex;
                            }
                        }

                    }
                }

                .submit {
                    gap: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @media only screen and (max-width: 470px) {
                        flex-direction: column;
                    }

                    button {
                        background-color: $c1;
                        border: none;
                        padding: 10px;
                        width: 25%;
                        font-size: .75rem;
                        cursor: pointer;
                        transition: background-color 0.3s ease-in, color 0.3s ease-in, border-color 0.3s ease-in;

                        @media only screen and (max-width: 425px) {
                            width: 100%;
                        }

                        &:hover {
                            background-color: $dc1;
                            color: #fff;
                            padding: 8px;
                            border: 2px solid $c1;

                            svg {
                                display: inline-flex;
                            }

                            p {
                                display: none;
                            }
                        }

                        svg {
                            color: $c1;
                            display: none;
                            width: 12px;
                            height: 12px;
                        }
                    }

                    .submit-text {
                        font-size: .75rem;
                        width: 300px;
                        display: none;

                        @media only screen and (max-width: 470px) {
                            text-align: center;
                            width: auto;
                        }
                    }

                    &.valid {
                        .submit-text {
                            display: flex;
                        }
                    }
                }

            }
        }

    }
}