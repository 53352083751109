.separator-content {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-block: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
    }

    .separator-block {
        z-index: 1;

        p {
            color: $c1;
            font-size: 14px;
            font-weight: 800;
        }

        h4 {
            font-size: 2rem;
            margin-block: 10px;
        }

        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;
            padding-inline: 10px;
            gap: 50px;

            li {
                list-style-type: none;

                h5 {
                    font-size: 1rem;
                    margin-bottom: 5px;
                }

                .round-parallax {
                    width: 75px;
                    height: 75px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $c1;
                    color: $dc1;
                }

                @media only screen and (max-width: $ss) {
                    h5 {
                        font-size: .85rem;
                    }

                    .round-parallax {
                        width: 75px;
                        height: 75px;
                    }
                }
            }
        }

        @media only screen and (max-width: $ss) {
            p {
                font-size: .75rem;
            }

            h4 {
                font-size: 1rem;
            }
        }
    }


}