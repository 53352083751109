header {
  background-image: url("./assets/10.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-bottom: 50px;
  height: 100vh;
  position: relative;

  @media only screen and (max-width: $ss) {
background-attachment: inherit;;
  }
  &::before {
    content: "";
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .top {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1240px;
    width: 100%;
    padding: 10px 25px;
    position: relative;
    z-index: 1;

    .top-icones {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      @media only screen and (max-width: 600px) {
        justify-content: center;
        width: 100%;
      }

      ul {
        display: flex;
        list-style: none;
        gap: 10px;
        margin-right: 20px;

        li a {
          color: #fff;
          transition: color 0.3s ease-in;
          display: flex;
          padding: 5px;
          border-radius: 50%;

          &:hover {
            background-color: $dc1;
            color: $c1;
            box-shadow: 0 2px 20px $c1;
          }
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }

      button {
        background-color: $c1;
        border: none;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        gap: 5px;
        color: $dc1;
        transition: background-color 0.3s ease-in, color 0.3s ease-in;

        &:hover {
          padding: 3px 8px;
          border: 2px solid $c1;
          background-color: inherit;
          color: $c1;
        }
      }
    }

    .top-contact {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 20px;

      @media only screen and (max-width: 600px) {
        display: none;
      }

      span {
        display: flex;
        align-items: center;
        gap: 5px;

        @media only screen and (max-width: 976px) {
          flex-direction: row-reverse;
        }

        svg {
          width: 20px;
          height: 20px;
        }

        a {
          color: #fff;
          text-decoration: none;
          transition: border-bottom 0.3s ease-in, color 0.3s ease-in;
          padding-block: 2px;

          &:hover {
            padding-bottom: 0;
            color: $c1;
            border-bottom: 2px solid $c1;
          }
        }
      }

      .contact-number,
      .contact-mail {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 12px;
      }
    }
  }

  .nav-background {
    background-color: rgba(255, 255, 255, 0.1);
    width: 100%;
    transition: background-color 0.3s ease-in;
    position: relative;
    z-index: 100;

    &.scrolled {
      top: 0;
      position: fixed;
      background-color: $dc3;
    }

    nav {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 1240px;
      width: 100%;
      padding-inline: 20px;

      h1 img {
        width: 40px;
        height: 40px;
      }

      .menu {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 33px;
        height: 100%;

        @media only screen and (max-width: 550px) {
          gap: 10px;
        }

        @media only screen and (max-width: 425px) {
          display: none;
        }

        .menu-title {
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
          padding-block: 5px;

          @media only screen and (max-width: 550px) {
            font-size: 0.75rem;

            svg {
              width: 24px;
              height: 24px;
            }
          }

          &:hover {
            color: $c1;
            padding-bottom: 0;
            border-bottom: 5px solid $c1;
          }
        }
      }

      .menu-mobile {
        display: none;

        @media only screen and (max-width: 425px) {
          display: flex;
        }
      }
    }
  }

  .presentation {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-block: 150px;
    position: relative;
    z-index: 1;

    .presentation-title {
      h2 {
        font-size: 3rem;
      }

      p {
        font-size: 1.25rem;
        text-align: center;
      }

      @media only screen and (max-width: 768px) {
        h2 {
          font-size: 2rem;
          text-align: center;
        }

        p {
          font-size: 0.75rem;
        }
      }

      @media only screen and (max-width: 480px) {
        h2 {
          font-size: 1.5rem;
        }

        p {
          font-size: 0.65rem;
        }
      }
    }

    .scroll-button {
      background: none;
      width: 50px;
      height: 100px;
      color: #fff;
      border-radius: 50px;
      border: 2px solid #fff;
      padding-block: 10px;
      margin-top: 10px;
      cursor: pointer;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  .modal {
    background-color: $dc1;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;

    .close-modal {
      display: flex;
      justify-content: flex-end;

      button {
        background-color: inherit;
        border: none;

        svg {
          color: #fff;
          padding: 5px;
          margin: 20px;
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      height: 100%;

      li {
        font-size: 2rem;
        text-align: center;
        font-weight: 900;
        padding-block: 35px;
        border-bottom: 2px solid $c1;

        &:last-child {
          border: none;
        }
      }
    }
  }
}

footer {
  margin-top: 150px;

  @media only screen and (max-width: 530px) {
    margin-top: 60px;
  }

  .link-footer {
    margin: 0 auto;
    max-width: 1240px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;

    @media only screen and (max-width: 500px) {
      flex-direction: column;
      gap: 15px;
    }

    img {
      width: 40px;
      height: 40px;
    }

    ul {
      list-style: none;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;

      li {
        display: flex;
        background-color: $dc1;
        border-radius: 50%;
        padding: 10px;
        transition: background-color 0.3s ease-in;

        &:hover {
          background-color: $c1;
        }

        a {
          color: #fff;

          svg {
            height: 100%;

            @media only screen and (max-width: 425px) {
              width: 20px;
            }
          }
        }
      }
    }
  }

  .texte-footer-background {
    background-color: $dc1;

    .texte-footer {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 1240px;
      width: 100%;
      padding: 30px 20px;
      gap: 30px;

      @media only screen and (max-width: 550px) {
        flex-direction: column;

        .infos-footer,
        .contact-footer {
          text-align: center;
        }
      }

      .infos-footer,
      .contact-footer {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        gap: 10px;

        @media only screen and (max-width: 768px) {
          h4 {
            font-size: 0.75rem;
          }
        }

        p {
          color: $c1;
          font-size: 0.75rem;

          @media only screen and (max-width: 768px) {
            font-size: 0.625rem;
          }
        }
      }

      .infos-footer {
        width: 550px;

        @media only screen and (max-width: 550px) {
          width: 100%;
        }
      }

      .contact-footer {
        align-items: flex-end;
        text-align: right;

        @media only screen and (max-width: 550px) {
          align-items: center;

          span {
            flex-direction: column-reverse;
          }
        }

        span {
          display: flex;
          align-items: center;
          color: $c2;
          gap: 10px;

          svg {
            width: 20px;
            height: 20px;
            color: $c1;
          }

          .mail-phone-info {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 10px;

            @media only screen and (max-width: 550px) {
              justify-content: center;
            }
          }

          a {
            text-decoration: none;
            color: $c1;
            font-size: 0.85rem;
            text-align: right;
            transition: color 0.3s ease-in, textdecoration 0.3s ease-in;

            @media only screen and (max-width: 768px) {
              font-size: 0.625rem;
            }

            &:hover {
              color: #fff;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .mention {
    margin: 0 auto;
    max-width: 1240px;
    width: 100%;
    padding: 25px 20px;
    display: flex;
    justify-content: space-between;
    position: relative;

    span {
      cursor: pointer;
      font-size: 1rem;
      font-weight: 300;
      transition: border-color 0.3s ease-in, color 0.3s ease-in;

      &:hover {
        color: $c1;
        border-bottom: 2px solid $c1;
      }
    }

    .copyright {
      font-size: 1rem;
      font-weight: 300;

      @media only screen and (max-width: 550px) {
        font-size: 0.75rem;

        &:last-child {
          text-align: right;
        }
      }
    }

    @media only screen and (max-width: 550px) {
      flex-direction: column;
      align-items: center;
    }
  }
}
