.cookie-consent-banner {
    background-image: url("./assets/cookie-background.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    bottom: 20px;
    z-index: 100;
    right: 20px;
    width: 300px;
    height: auto;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &::before {
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, .75);
        border-radius: 10px;
        z-index: -1;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    h5 {
        font-size: 1rem;
    }

    p {
        font-weight: 600;
        font-size: .75rem;
    }

    .cookie-block {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-weight: 400;
        }

        .toggle-background {
            background-color: $c2;
            border-radius: 20px;
            color: #fff;
            cursor: pointer;
            display: flex;
            margin-left: 5px;
            padding-right: 5px;
            width: 50px;
            height: 25px;
            align-items: center;
            transition: background-color 0.3s ease-in;

            .animate-translate {
                transform: translateX(0%);
                transition: transform 0.3s ease-in;

                button {
                    background-color: #fff;
                    border: none;
                    border-radius: 50%;
                    color: $c1;
                    cursor: pointer;
                    display: flex;
                    font-size: 1rem;
                    font-weight: 900;
                    justify-content: center;
                    padding: 5px;
                    width: 25px;
                    height: 25px;
                }
            }

            &.active {
                background-color: $c1;

                .animate-translate {
                    transform: translateX(100%);
                }
            }

        }
    }

    @media only screen and (max-width: 425px) {
        width: 100%;
        bottom: 0;
        right: 0;
    }

    button {
        border: 2px solid $c1;
        background-color: inherit;
        color: $c1;
        border-radius: 5px;
        padding-block: 5px;
        transition: color .3s ease-in, background-color .3s ease-in;
        cursor: pointer;

        &:hover {
            color: $dc1;
            font-weight: 900;
            border: none;
            background-color: $c1;
            padding-block: 7px;
        }
    }
}