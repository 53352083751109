.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $dc3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 80px;
    height: auto;
  }
  h1 {
    font-family: "Montserrat", sans-serif;
    color: $c1;
    height: auto;
    //   color: rgb(201, 171, 129);
    text-decoration: none;
    white-space: normal;
    width: 894px;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    text-align: center;
    line-height: 145px;
    letter-spacing: 28px;
    font-weight: 300;
    font-size: 50px;
  }
  h2 {
    color: white;
    font-size: 35px;
    margin-bottom: 20px;
  }

  .top-icones {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 100px;

    @media only screen and (max-width: 600px) {
      justify-content: center;
      width: 100%;
    }

    ul {
      display: flex;
      list-style: none;
      gap: 10px;

      li a {
          transition: color 0.3s ease-in;
          display: flex;
          padding: 5px;
          border-radius: 50%;
          
          background-color: $dc1;
          color: $c1;
          box-shadow: 0 2px 20px $c1;
          &:hover {
            color: #fff;
        }
      }
    }
}
}