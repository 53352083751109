/* Couleur du site */

$dc1: #2a2a2a;
$dc2: #222222;
$dc3: #1a1a1a;
$c1: #cbb488;
$c2: #8F8F8F;

/* Taille d'écran */

$ss: 425px;
$ms: 768px;
$ls: 1024px;

/* Style global */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

body {
    background-color: $dc2;
    color: #FFF;
}

body.modal-open {
    overflow: hidden;
}

@mixin title {
    margin: 0 auto;
    max-width: 1240px;
    width: 100%;
    padding-top: 250px;

    @media only screen and (max-width: 768px) {
        padding-top: 100px;
    }

    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        .animated-heading {
            font-size: 3rem;
            margin-bottom: 30px;
            text-align: center; 
        }
        h3 {
            font-size: 3rem;
            margin-bottom: 30px;
            text-align: center;
        }

        h4 {
            text-align: center;
            font-size: 1.75rem;
            color: $c1;
            margin: 0 10px 30px 10px;
        }

        @media only screen and (max-width: 915px) {
            h3 {
                font-size: 2rem;
                text-align: center;
            }
            .animated-heading {    font-size: 2rem;
    
            text-align: center;}
            h4 {
                font-size: 1.25rem;
            }
        }

        .line-barre {
            width: 100px;
            height: 7px;
            margin-bottom: 80px;
            background-color: $c1;
            border: none;
        }
    }
}