.modal-overlay {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    padding: 50px;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    z-index: 1000;

    .modal {
        background-color: $dc1;
        padding: 20px;
        border-radius: 10px;
        height: 100%;
        z-index: 1000;
        overflow-y: auto;

        .close-modal {
            display: flex;
            justify-content: flex-end;
            position: fixed;
            top: 0;
            right: 0;

            button {
                background-color: inherit;
                border: none;

                svg {
                    color: #fff;
                    padding: 5px;
                    margin: 15px;
                }
            }
        }

        .modal-content {
            gap: 30px;
            display: flex;
            flex-direction: column;

            .modal-title {
                display: flex;
                align-items: flex-start;

                h2 {
                    width: 100%;
                }

                .society-name {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    height: 40px;
                    width: 100%;
                    gap: 20px;

                    img {
                        width: 40px;
                    }

                    p {
                        font-size: 1rem;
                        font-weight: 900;

                        @media only screen and (max-width: 768px) {
                            font-size: .75rem;
                        }
                    }
                }

                @media only screen and (max-width: 768px) {
                    flex-direction: column;
                    text-align: center;
                    align-items: center;
                    gap: 10px;

                    .society-name {
                        width: auto;
                        gap: 10px;

                        img {
                            width: 30px;
                        }
                    }
                }
            }

            .legal-notice-content {
                gap: 5px;
                display: flex;
                flex-direction: column;

                h3 {
                    margin-bottom: 5px;
                }

                b {
                    color: $c1;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                @media only screen and (max-width: 1024px) {
                    font-size: .75rem;
                }

                @media only screen and (max-width: 425px) {
                    align-items: center;
                    text-align: center;

                    p {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }

            .general-condition {

                ul {
                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    li {
                        display: flex;
                        flex-direction: column;
                        font-size: 1.5rem;
                        font-weight: 900;
                        padding-block: 20px;
                        border-bottom: 2px solid $c1;
                        list-style: none;
                        gap: 10px;

                        &:last-child {
                            border: none;
                        }

                        .article-content {
                            gap: 10px;
                            display: flex;
                            flex-direction: column;

                            p {
                                font-weight: 400;
                                font-size: .75rem;
                            }
                        }
                    }

                }
            }

            .confidential-intro {
                font-size: .85rem;
                @media only screen and (max-width: 425px) {
                    font-size: .75rem;
                    text-align: center;
                }
            }

            .confidential-block {
                gap: 20px;
                display: flex;
                flex-direction: column;
                list-style: none;

                p {
                    font-size: .75rem;
                }

                li {
                    gap: 10px;
                    display: flex;
                    flex-direction: column;

                    ul {
                        list-style: none;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;

                        p,
                        ul {
                            margin-left: 20px;
                        }
                    }
                }


            }

        }


        @media only screen and (max-width: 425px) {
            width: 100%;
            margin: 0;
        }
    }

    h2 {
        font-size: 3rem;
        font-weight: 900;
        color: $c1;
    }

    h3 {
        font-size: 2rem;
    }

    h4 {
        font-size: 1.5rem;
        color: $c1;
    }

    @media only screen and (max-width: 1024px) {
        h2 {
            font-size: 2rem;
        }

        h3 {
            font-size: 1.5rem;
        }

        h4 {
            font-size: 1rem;
            font-size: 1.5rem;
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 20px;

        h2 {
            font-size: 1.5rem;
            text-align: center;
        }

        h3 {
            font-size: 1.25rem;
        }

        h4 {
            font-size: .85rem;
        }
    }
}