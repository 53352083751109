#services {
  @include title;
  padding-bottom: 100px;

  .services-content {
    background-image: url("./assets/word-img.png");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-inline: 20px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $dc2;
      opacity: .75;
    }

    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 100%;
      justify-content: space-between;
      margin-bottom: 40px;
      gap: 20px;
      z-index: 1;

      @media only screen and (max-width: 680px) {
        width: 100%;
      }

      li {
        transition: text-decoration-color 0.3s ease-in, text-decoration-thickness 0.3s ease-in;
        @media only screen and (max-width: 680px) {
          gap: 20px;
        }

        &:hover {
          text-decoration: underline;
          text-decoration-color: $c1;
          text-decoration-thickness: 2px;

          & .round {
            background-color: $c1;

            svg {
              color: $dc1;
            }
          }
        }

        .block-texte {
          display: flex;
          flex-direction: column;

          h5 {
            font-size: 1rem;
          }

          p {
            font-size: .75rem;
          }
        }

        .round {
          border: solid 2px;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $c1;
          transition: background-color 0.3s ease-in, color 0.3s ease-in;

          svg {
            color: $c1;
            display: flex;
            align-items: center;
          }
        }
      }

      &.block-left li {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;

        @media only screen and (max-width: 680px) {
          flex-direction: column-reverse;

          .block-texte {
            align-items: center;
            text-align: center;
          }
        }

        .block-texte {
          margin-left: 20px;
        }
      }

      &.block-right li {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .block-texte {
          align-items: flex-end;
          margin-right: 20px;
        }

        @media only screen and (max-width: 1217px) {
          flex-direction: row-reverse;

          .block-texte {
            align-items: flex-start;
            margin-left: 20px;
          }
        }

        @media only screen and (max-width: 680px) {
          flex-direction: column-reverse;

          .block-texte {
            align-items: center;
            text-align: center;
          }
        }
      }
    }

    .services-block-first-img {
      width: 350px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 42%;
      clip-path: polygon(0 0, 100% 70%, 100% 100%, 0 30%);
      opacity: 60%;
      z-index: -1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}